import { Button, Divider, Group, Input, NumberInput, SegmentedControl, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle } from '@tabler/icons-react';
import { ModalBase, LanguageSelect, languageSelectTestIds } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DataGenerationRouteParams } from 'routes/routes.config';
import { useAiDataSourcesStore, DataGenSettings } from 'stores/aiPlatform';
import { useFlow } from 'stores/flows';

type FormState = {
  language: string;
  sliceRatio: number;
  noiseRatio: number;
} & (
  | {
      inputSource: 'external';
      externalTextFile: string;
    }
  | {
      inputSource: 'internal';
    }
);

interface VoiceDataGenerationSettingsModalProps {
  opened: boolean;
  settings?: DataGenSettings;
  sampleExecutionId?: string;
  onClose: () => void;
}

export const testIds = {
  wrapper: 'voice-data-generation-settings-modal',
  inputSource: 'voice-data-generation-settings-modal-input-source',
  externalTextFile: 'voice-data-generation-settings-modal-external-text-file',
  language: languageSelectTestIds,
  sliceRatio: 'voice-data-generation-settings-modal-slice-ratio',
  noiseRatio: 'voice-data-generation-settings-modal-noise-ratio',
  cancelButton: 'voice-data-generation-settings-modal-cancel-button',
  submitButton: 'voice-data-generation-settings-modal-submit-button',
};

export const VoiceDataGenerationSettingsModal = ({
  opened,
  settings,
  sampleExecutionId,
  onClose,
}: VoiceDataGenerationSettingsModalProps) => {
  const readOnly = !!settings;
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const [submitting, setSubmitting] = useState(false);
  const form = useForm<FormState>({
    initialValues: {
      inputSource: 'external',
      externalTextFile: settings?.externalTextFile ?? '',
      language: settings?.language ?? 'en-US',
      sliceRatio: settings?.voiceGenSettings?.sliceRatio ?? 1,
      noiseRatio: settings?.voiceGenSettings?.noiseRatio ?? 0,
    },
    validate: {
      sliceRatio: (value) =>
        (value < 0 && t('dataGenerationPage.generateModal.initialSamplesInput.tooLowError', { count: 0 })) ||
        (value > 1 && t('dataGenerationPage.generateModal.initialSamplesInput.tooHighError', { count: 1 })),
      noiseRatio: (value) =>
        (value < 0 && t('dataGenerationPage.generateModal.numberOfSamplesInput.tooLowError', { count: 0 })) ||
        (value > 1 && t('dataGenerationPage.generateModal.numberOfSamplesInput.tooHighError', { count: 1 })),
    },
    validateInputOnBlur: true,
    enhanceGetInputProps: () => ({ disabled: readOnly }),
  });
  const flow = useFlow(flowId);
  const { createAiDataSource } = useAiDataSourcesStore(['createAiDataSource']);

  const onSubmit = async (formState: FormState) => {
    setSubmitting(true);
    // Currently internal input is unsupported
    if (formState.inputSource === 'internal') return;

    // TODO: Temporary fix for this tenant until properly fixed in BE
    const tenantId = customerId.replace(/^aiola$/, 'aiola-dev');
    const { externalTextFile, language } = formState;

    const response = await createAiDataSource({
      flowId,
      flowVersion: flow.activeVersion!,
      tenantId,
      sampleExecutionId,
      dataGenSettings: {
        language,
        onlyTextSamples: false,
        externalTextFile,
        numSamples: 0,
        voiceGenSettings: {
          sliceRatio: formState.sliceRatio,
          noiseRatio: formState.noiseRatio,
        },
        textGenSettings: {
          structuredTextGen: false,
        },
      },
    });
    if (response) {
      onClose();
    } else {
      notifications.show({
        color: 'red',
        title: t('dataGenerationPage.generateModal.errorMessage'),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    }
    setSubmitting(false);
  };

  return (
    <ModalBase
      opened={opened}
      title={t('dataGenerationPage.generateModal.title', { modelType: t('common.modelType.asr') })}
      onClose={onClose}
      data-testid={testIds.wrapper}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack gap='lg'>
          <Stack gap={0}>
            <Input.Label required>{t('dataGenerationPage.generateModal.nlpSource.title')}</Input.Label>
            <Input.Description my={4}>{t('dataGenerationPage.generateModal.nlpSource.description')}</Input.Description>
            <SegmentedControl
              data={[
                {
                  value: 'internal',
                  label: t('dataGenerationPage.generateModal.nlpSource.internal'),
                  disabled: true,
                },
                { value: 'external', label: t('dataGenerationPage.generateModal.nlpSource.external') },
              ]}
              {...form.getInputProps('inputSource')}
              data-testid={testIds.inputSource}
            />
          </Stack>
          <TextInput
            label={t('dataGenerationPage.generateModal.externalTextFileInput.title')}
            placeholder={t('dataGenerationPage.generateModal.externalTextFileInput.placeholder')}
            required
            {...form.getInputProps('externalTextFile')}
            data-testid={testIds.externalTextFile}
          />
          <Divider />
          <Stack>
            <LanguageSelect
              initialValue='en-US'
              label={t('dataGenerationPage.generateModal.languageInput.title')}
              placeholder={t('dataGenerationPage.generateModal.languageInput.placeholder')}
              {...form.getInputProps('language')}
              required
            />
            <NumberInput
              label={t('dataGenerationPage.generateModal.sliceRatioInput.title')}
              description={t('dataGenerationPage.generateModal.sliceRatioInput.description')}
              required
              {...form.getInputProps('sliceRatio', { withError: true })}
              data-testid={testIds.sliceRatio}
            />
            <NumberInput
              label={t('dataGenerationPage.generateModal.noiseRatioInput.title')}
              description={t('dataGenerationPage.generateModal.noiseRatioInput.description')}
              required
              {...form.getInputProps('noiseRatio', { withError: true })}
              data-testid={testIds.noiseRatio}
            />
          </Stack>
          <Group justify='end' pt='lg'>
            <Button variant='subtle' onClick={onClose} data-testid={testIds.cancelButton}>
              {t('common.cancel')}
            </Button>
            <Button
              variant='submit'
              loading={submitting}
              disabled={readOnly}
              type='submit'
              data-testid={testIds.submitButton}
            >
              {t('dataGenerationPage.generateModal.submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </ModalBase>
  );
};
