import { exists } from '@aiola/frontend';
import { Box, Button, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalBase, ModalCommonProps } from 'components';
import { TextDataGenerationSettingsModal } from 'pages/aiPlatform/DataGenerationPage/TextDataGenerationSettingsModal/TextDataGenerationSettingsModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiDataSourceId, DataGenSettings } from 'stores/aiPlatform';
import { DataSourceSelect, testIds as selectTestIds } from './components/DataSourceSelect';

interface TraingingModalProps extends ModalCommonProps {
  modelName: string;
  onStart: (aiDataSourceId: AiDataSourceId) => Promise<void>;
}

export const testIds = {
  title: 'train-model-title',
  ctaNegative: 'train-model-cancel-button',
  ctaPositive: 'train-model-train-button',
  message: 'train-model-message',
  select: selectTestIds,
};

export const TrainingModal = ({ modelName, opened, onClose, onStart }: TraingingModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState<string | null>(null);
  const { t } = useTranslation();
  const [settingsOption, setSettingOption] = useState<DataGenSettings | null>(null);
  const [dataSourceSettingOpened, { open: dataSourceSettingOpen, close: dataSourceSettingClose }] =
    useDisclosure(false);

  const onDataSourceSettingOpen = (settings: DataGenSettings) => {
    setSettingOption(settings);
    dataSourceSettingOpen();
  };
  const title = t('trainingPage.trainigModal.title', { modelName });
  const ctaNegative = t('trainingPage.trainigModal.ctaNegative');
  const ctaPositive = t('trainingPage.trainigModal.ctaPositive');
  const message = t('trainingPage.trainigModal.message');

  const isSelectedDataSourceSet = exists(selectedDataSource);

  const handleStart = async () => {
    if (!isSelectedDataSourceSet) return;
    setSubmitting(true);
    await onStart(selectedDataSource);
    setSubmitting(false);
  };

  return (
    <ModalBase size='lg' title={title} onClose={onClose} opened={opened}>
      <Text className='whitespace-pre-line' c='gray.7' my='lg' data-testid={testIds.message}>
        {message}
      </Text>
      <Box py='sm'>
        <DataSourceSelect
          forceOpenOptions={dataSourceSettingOpened}
          onDataSourceOpen={onDataSourceSettingOpen}
          onChange={setSelectedDataSource}
        />
      </Box>
      <Group justify='end' pt='lg'>
        <Button variant='subtle' onClick={onClose} data-testid={testIds.ctaNegative}>
          {ctaNegative}
        </Button>
        <Button
          variant='submit'
          loading={submitting}
          disabled={!isSelectedDataSourceSet}
          onClick={handleStart}
          data-testid={testIds.ctaPositive}
        >
          {ctaPositive}
        </Button>
      </Group>
      {settingsOption?.textGenSettings && (
        <TextDataGenerationSettingsModal
          opened={dataSourceSettingOpened}
          settings={settingsOption}
          modalBaseProps={{ zIndex: 1000 }}
          onClose={dataSourceSettingClose}
        />
      )}
    </ModalBase>
  );
};
