import { Flex, Button, Slider, Text, Group, Stack, ThemeIcon, Tooltip } from '@mantine/core';
import { IconExclamationCircle, IconPlayerPauseFilled, IconPlayerPlayFilled } from '@tabler/icons-react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './AudioPlayer.module.css';

const secondsToTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${minutes}:${seconds.toFixed(0).padStart(2, '0')}`;
};

interface AudioPlayerProps {
  src: string;
}

export const testIds = {
  audio: 'audio-player',
  playButton: 'audio-player-play-button',
  playIcon: 'audio-player-play-icon',
  pauseIcon: 'audio-player-pause-icon',
  slider: 'audio-player-slider',
  currentTime: 'audio-player-current-time',
  totalTime: 'audio-player-total-time',
  errorTooltip: 'audio-player-error-tooltip',
  errorIcon: 'audio-player-error-icon',
};

export const AudioPlayer = ({ src }: AudioPlayerProps) => {
  const [playing, setPlaying] = useState(false);
  const [error, setError] = useState('');
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const { t } = useTranslation();
  const trackPercentage = totalTime ? (currentTime / totalTime) * 100 : 0;

  const togglePlay = () => {
    if (!audioRef.current) return;
    setPlaying((prev) => !prev);
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const changeCurrentTime = (value: number) => {
    const newCurrentTime = (value / 100) * totalTime;
    setCurrentTime(newCurrentTime);
    audioRef.current!.currentTime = newCurrentTime;
  };

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    audioRef.current.ontimeupdate = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
      }
    };
    audioRef.current.onloadedmetadata = () => {
      if (audioRef.current) {
        setTotalTime(audioRef.current.duration);
      }
    };
    audioRef.current.onended = () => {
      setPlaying(false);
    };
    audioRef.current.onerror = () => {
      setPlaying(false);
      setError(t('dataGenerationPage.sampleTable.voice.playbackError'));
    };
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} src={src} data-testid={testIds.audio} />
      <Stack gap={0} w='100%'>
        <Flex align='center' gap='sm'>
          <Button variant='transparent' color='gray' onClick={togglePlay} data-testid={testIds.playButton}>
            {playing ? (
              <IconPlayerPauseFilled data-testid={testIds.pauseIcon} />
            ) : (
              <IconPlayerPlayFilled data-testid={testIds.playIcon} />
            )}
          </Button>
          <Slider
            className='grow'
            size='xs'
            value={trackPercentage}
            min={0}
            max={100}
            step={1}
            thumbSize={12}
            classNames={classes}
            onChange={changeCurrentTime}
            data-testid={testIds.slider}
          />
          <Group gap={0} c='gray.4'>
            <Text c='dark.3' data-testid={testIds.currentTime}>
              {secondsToTime(currentTime)}
            </Text>
            /<Text data-testid={testIds.totalTime}>{totalTime ? secondsToTime(totalTime) : '-'}</Text>
          </Group>
          {error && (
            <Tooltip label={error} maw={200} data-testid={testIds.errorTooltip}>
              <ThemeIcon color='red' size='sm' variant='transparent'>
                <IconExclamationCircle data-testid={testIds.errorIcon} />
              </ThemeIcon>
            </Tooltip>
          )}
        </Flex>
      </Stack>
    </>
  );
};
