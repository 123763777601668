import { Button, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { ModalBase } from 'components';
import { useTranslation } from 'react-i18next';
import { AiDataSource } from 'stores/aiPlatform';

interface DataGenerationErrorModalProps {
  aiDataSource: AiDataSource;
  opened: boolean;
  onClose: () => void;
  onRetry: () => void;
}

export const testIds = {
  container: 'data-generation-failed-modal',
  icon: 'data-generation-failed-modal-icon',
  title: 'data-generation-failed-modal-title',
  message: 'data-generation-failed-modal-message',
  retryButton: 'data-generation-failed-modal-retry-button',
};

export const DataGenerationErrorModal = ({ aiDataSource, opened, onClose, onRetry }: DataGenerationErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalBase opened={opened} onClose={onClose} title='' data-testid={testIds.container}>
      <Stack align='center' ta='center' gap={24}>
        <ThemeIcon variant='white' color='red' size={80}>
          <IconAlertTriangleFilled size={80} data-testid={testIds.icon} />
        </ThemeIcon>
        <Stack gap={0}>
          <Text fz='lg' fw={600} data-testid={testIds.title}>
            {t('dataGenerationPage.errorModal.title')}
          </Text>
          <Text c='gray.6' data-testid={testIds.message}>
            {t('dataGenerationPage.errorModal.message', { id: aiDataSource.id })}
          </Text>
        </Stack>
        <Button color='gray.7' w='100%' onClick={onRetry} data-testid={testIds.retryButton}>
          {t('dataGenerationPage.errorModal.retryButton')}
        </Button>
      </Stack>
    </ModalBase>
  );
};
