import i18n from 'services/i18n';
import { MantineColor } from '@mantine/core';
import { AiModelStatus } from 'stores/aiPlatform/aiModels';

export const statusLabels: Record<AiModelStatus, string> = {
  [AiModelStatus.ABORTED]: i18n.t('common.modelStatus.aborted'),
  [AiModelStatus.DECLINED]: i18n.t('common.modelStatus.declined'),
  [AiModelStatus.FAILED]: i18n.t('common.modelStatus.failed'),
  [AiModelStatus.IN_TRAINING]: i18n.t('common.modelStatus.inTraining'),
  [AiModelStatus.PAUSED]: i18n.t('common.modelStatus.paused'),
  [AiModelStatus.COMPLETED]: i18n.t('common.modelStatus.completed'),
} as const;

export const statusColors: Record<AiModelStatus, MantineColor> = {
  [AiModelStatus.ABORTED]: 'red',
  [AiModelStatus.FAILED]: 'red',
  [AiModelStatus.DECLINED]: 'red',
  [AiModelStatus.IN_TRAINING]: 'blue',
  [AiModelStatus.PAUSED]: 'yellow',
  [AiModelStatus.COMPLETED]: 'green',
} as const;
