import { User, UserId } from 'stores/auth';
import { CustomerId } from 'stores/customers';
import { StateCreator } from 'zustand';
import {
  CreateGroupPayload,
  CreatePolicyPayload,
  CreateUserPayload,
  Group,
  Permission,
  Policy,
  UpdateGroupPayload,
  UpdatePolicyPayload,
  UpdateUserPayload,
  UserMgmtCSVResponse,
} from '../userMgmt.types';

export interface UsersSliceState {
  users: Record<UserId, User>;
  loadingUsers: boolean;
}

export type UpsertUserArgs =
  | { action: 'create'; userId?: never; payload: CreateUserPayload }
  | { action: 'update'; userId: UserId; payload: UpdateUserPayload };

export interface UsersSliceActions {
  fetchUsers: (customerId: CustomerId) => Promise<boolean>;
  upsertUser: (customerId: CustomerId, args: UpsertUserArgs) => Promise<User | undefined>;
  createUsersFromFile: (customerId: CustomerId, file: File) => Promise<UserMgmtCSVResponse>;
  deleteUser: (customerId: CustomerId, userId: UserId) => Promise<boolean>;
}

export enum UserCSVColumnName {
  EMAIL = 'email',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
}

export type UsersParsedCSVPayload = Record<UserCSVColumnName, string>;

export interface PolicySliceState {
  policies: Record<string, Policy>;
  loadingPolicies: boolean;
}

export type UpsertPolicyArgs =
  | { action: 'create'; policyId?: never; payload: CreatePolicyPayload }
  | { action: 'update'; policyId: string; payload: UpdatePolicyPayload };

export type UpsertPermissionArgs =
  | { action: 'create'; policyId: string; permissionId?: never; payload: Permission }
  | { action: 'update'; policyId: string; permissionId: string; payload: Permission };

export interface PolicySliceActions {
  fetchPolicies: (customerId: CustomerId) => Promise<boolean>;
  upsertPolicy: (customerId: CustomerId, args: UpsertPolicyArgs) => Promise<Policy | undefined>;
  deletePolicy: (customerId: CustomerId, policyId: string) => Promise<boolean>;
  upsertPermission: (customerId: CustomerId, args: UpsertPermissionArgs) => Promise<Policy | undefined>;
  deletePermission: (customerId: CustomerId, policyId: string, permissionId: string) => Promise<boolean>;
}

export interface GroupSliceState {
  groups: Record<string, Group>;
  loadingGroups: boolean;
}

export type UpsertGroupArgs =
  | { action: 'create'; groupId?: never; payload: CreateGroupPayload }
  | { action: 'update'; groupId: string; payload: UpdateGroupPayload };

export interface GroupSliceActions {
  fetchGroups: (customerId: CustomerId) => Promise<boolean>;
  upsertGroup: (customerId: CustomerId, args: UpsertGroupArgs) => Promise<Group | undefined>;
  deleteGroup: (customerId: CustomerId, groupId: string) => Promise<boolean>;
}

export type UserMgmtState = UsersSliceState &
  UsersSliceActions &
  PolicySliceState &
  GroupSliceState &
  GroupSliceActions &
  PolicySliceActions & { reset: () => void };

export type UserMgmtSlice<State, Actions> = StateCreator<
  UserMgmtState,
  [['zustand/immer', never]],
  [],
  State & Actions
>;
