import { ActionIcon, DEFAULT_THEME, MantineStyleProp } from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { IconClock } from '@tabler/icons-react';
import { useState, ChangeEvent, useRef } from 'react';
import { timeStringToMinutes, minutesToTimeString } from 'utils';

interface TimeSingleInputProps {
  /* Time in minutes */
  value: number | null;
  onChange: (value: number | null) => void;
  error?: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  className?: string;
  style?: MantineStyleProp;
}

export const testIds = {
  input: 'time-single-input',
  actionIcon: 'time-single-input-action-icon',
};

export const TimeSingleInput = ({ value, onChange, error, disabled, style, ...props }: TimeSingleInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [dirty, setDirty] = useState(false);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(timeStringToMinutes(event.target.value) || null);
    setDirty(true);
  };

  const isValueNull = value === null;
  const time = isValueNull ? '' : minutesToTimeString(value);
  const iconColor = dirty && error ? DEFAULT_THEME.colors.red[6] : DEFAULT_THEME.colors.gray[6];

  return (
    <TimeInput
      ref={ref}
      style={style}
      error={dirty && error}
      value={time}
      disabled={disabled}
      leftSection={
        <ActionIcon
          disabled={disabled}
          variant='subtle'
          color={iconColor}
          onClick={() => ref.current?.showPicker()}
          data-testid={testIds.actionIcon}
        >
          <IconClock />
        </ActionIcon>
      }
      onChange={onChangeValue}
      data-testid={testIds.input}
      {...props}
    />
  );
};
