import dayjs from 'dayjs';
import { Flex, Group, Stack, Text } from '@mantine/core';
import { ActionButton, InfoTip, UserDetails } from 'components';
import { useTranslation } from 'react-i18next';
import { AiDataSource, AiDataSourceSamplesType, AiDataSourceStatus } from 'stores/aiPlatform';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import { IconAdjustments, IconDownload } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { DataGenerationStatusBadge } from '../DataGenerationStatusBadge/DataGenerationStatusBadge';
import { TextDataGenerationSettingsModal } from '../../TextDataGenerationSettingsModal/TextDataGenerationSettingsModal';
import { VoiceDataGenerationSettingsModal } from '../../VoiceDataGenerationSettingsModal/VoiceDataGenerationSettingsModal';

interface DataGenerationCardHeaderProps {
  aiDataSource: AiDataSource;
}

export const testIds = {
  avatar: 'data-generation-card-header-avatar',
  status: 'data-generation-card-header-status',
  date: 'data-generation-card-header-date',
  time: 'data-generation-card-header-time',
  settings: 'data-generation-card-header-settings',
  download: 'data-generation-card-header-download',
  tip: 'data-generation-card-header-tip',
};

export const DataGenerationCardHeader = ({ aiDataSource }: DataGenerationCardHeaderProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);
  const { id, status, createdBy, createdAt, samplesType } = aiDataSource;
  const [date, time] = dayjs(createdAt).format(CLASSIC_DATE_TIME_FORMAT).split(' ');

  return (
    <>
      <Flex justify='space-between'>
        <UserDetails user={createdBy} data-testid={testIds.avatar} />
        <Group gap='lg'>
          <DataGenerationStatusBadge status={status} data-testid={testIds.status} />
          <Stack gap={0}>
            <Text size='sm' c='dark.3' data-testid={testIds.date}>
              {date}
            </Text>
            <Text size='xs' c='dark.2' data-testid={testIds.time}>
              {time}
            </Text>
          </Stack>
          <Group>
            <ActionButton
              label={t('dataGenerationPage.card.settingsLabel')}
              onClick={open}
              data-testid={testIds.settings}
            >
              <IconAdjustments />
            </ActionButton>
            <ActionButton
              label={t('dataGenerationPage.card.downloadLabel')}
              disabled={aiDataSource.status !== AiDataSourceStatus.COMPLETED}
              data-testid={testIds.download}
            >
              <IconDownload />
            </ActionButton>
            <InfoTip data-testid={testIds.tip}>{t('dataGenerationPage.card.tip', { id })}</InfoTip>
          </Group>
        </Group>
      </Flex>

      {samplesType === AiDataSourceSamplesType.TEXT && (
        <TextDataGenerationSettingsModal opened={opened} settings={aiDataSource.settings} onClose={close} />
      )}
      {samplesType === AiDataSourceSamplesType.VOICE && (
        <VoiceDataGenerationSettingsModal opened={opened} settings={aiDataSource.settings} onClose={close} />
      )}
    </>
  );
};
