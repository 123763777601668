import { TextDataSourceSample, VoiceDataSourceSample } from '@ai-platform/data-generation-types';
import { createStoreHook } from '@aiola/frontend';
import { keyBy } from 'utils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { ListDataGenerationsInput, ModelType, StartDataGenerationRequest } from '../aiPlatformBackendTypes';
import { endSubscriptionStatusList } from './aiDataSources.const';
import {
  AiDataSource,
  AiDataSourceId,
  AiDataSourceSamplesType,
  AiDataSourceStatus,
  AiDataSourceSubscriptionOptions,
  AiDataTextSample,
  AiDataVoiceSample,
} from './aiDataSources.types';
import { aiDataSourcesApi } from './api/aiDataSources.api';

interface AiDataSourcesState {
  loading: boolean;
  aiDataSources: Record<AiDataSourceId, AiDataSource>;
  aiSamples: Record<AiDataSourceId, AiDataTextSample[] | AiDataVoiceSample[]>;
  subscriptions: Record<AiDataSourceId, NodeJS.Timeout>;
}

interface AiDataSourcesActions {
  fetchAiDataSources: (input: ListDataGenerationsInput) => Promise<AiDataSource[] | undefined>; // deprecated use fetchLatestAiDataSources
  fetchLatestAiDataSources: (
    input: ListDataGenerationsInput,
    dataSourceType?: ModelType,
  ) => Promise<AiDataSource[] | undefined>;
  fetchSingleAiDataSource: (executionId: string) => Promise<AiDataSource | undefined>;
  fetchDataSourceSamples: (
    executionId: string,
    modelType: ModelType,
  ) => Promise<TextDataSourceSample[] | VoiceDataSourceSample[]>;
  createAiDataSource: (input: StartDataGenerationRequest) => Promise<AiDataSource | undefined>;
  subscribeToAiDataSource: (id: AiDataSourceId, options?: AiDataSourceSubscriptionOptions) => () => void;

  abortGeneration: (id: AiDataSourceId) => Promise<boolean>;
  declineGeneration: (id: AiDataSourceId) => Promise<boolean>;
  reset: () => void;
  internal: {
    unsubscribeFromAiDataSource: (id: AiDataSourceId) => void;
  };
}

const initialState: AiDataSourcesState = {
  loading: false,
  aiDataSources: {},
  subscriptions: {},
  aiSamples: {},
};

export const aiDataSourcesStore = create(
  immer<AiDataSourcesState & AiDataSourcesActions>((set, get) => ({
    ...initialState,
    fetchLatestAiDataSources: async (input: ListDataGenerationsInput, dataSourceType?: ModelType) => {
      set({ loading: true });
      const latestAiDataSourceList = await aiDataSourcesApi.getLatestAiDataSourcesByType(input, dataSourceType);
      const aiDataSources = keyBy(latestAiDataSourceList ?? [], 'id');
      const latestAiDataSource = latestAiDataSourceList && latestAiDataSourceList[0];
      if (latestAiDataSource?.status === AiDataSourceStatus.COMPLETED && latestAiDataSource.isSamplesGeneration) {
        await get().fetchDataSourceSamples(
          latestAiDataSource.id,
          latestAiDataSource.samplesType === AiDataSourceSamplesType.TEXT ? ModelType.NLP : ModelType.ASR,
        );
      }
      set((state) => {
        state.aiDataSources = { ...state.aiDataSources, ...aiDataSources };
        state.loading = false;
      });

      return latestAiDataSourceList;
    },
    fetchAiDataSources: async (input: ListDataGenerationsInput) => {
      set({ loading: true });
      const aiDataSourceList = await aiDataSourcesApi.getAiDataSources(input);
      const aiDataSources = keyBy(aiDataSourceList ?? [], 'id');
      set({ aiDataSources, loading: false });
      return aiDataSourceList;
    },
    fetchSingleAiDataSource: async (executionId: string) => {
      const aiDataSource = await aiDataSourcesApi.getAiDataSource(executionId);
      set((state) => {
        if (aiDataSource) {
          state.aiDataSources[aiDataSource.id] = aiDataSource;
        }
      });
      return aiDataSource;
    },
    fetchDataSourceSamples: async (
      executionId: string,
      modelType: ModelType,
    ): Promise<AiDataTextSample[] | AiDataVoiceSample[]> => {
      const rawSamples = await aiDataSourcesApi.getDataSourceSamples(executionId, modelType.toLowerCase());

      const samples = rawSamples?.map((dataSource, index) => ({
        ...dataSource,
        id: index.toString(),
      }));

      const retSamples =
        modelType === ModelType.NLP ? (samples as AiDataTextSample[]) : (samples as AiDataVoiceSample[]);

      set((state) => {
        state.aiSamples[executionId] = retSamples;
      });

      return retSamples;
    },

    createAiDataSource: async (input: StartDataGenerationRequest) => {
      const response = await aiDataSourcesApi.startDataSourceGeneration(input);
      if (!response.executionId) {
        return undefined;
      }
      const aiDataSource = await aiDataSourcesApi.getAiDataSource(response.executionId);
      if (!aiDataSource) {
        return undefined;
      }
      set((state) => {
        state.aiDataSources[aiDataSource.id] = aiDataSource;
      });
      return aiDataSource;
    },
    subscribeToAiDataSource: (
      id: AiDataSourceId,
      { onComplete, onFail, unsubscribeOnFinish }: AiDataSourceSubscriptionOptions = {},
    ) => {
      const interval = aiDataSourcesApi.subscribeToAiDataSource(id, async (aiDataSource) => {
        const prevStatus = get().aiDataSources[aiDataSource.id]?.status;
        const { step: currentStep, status: currentStatus } = aiDataSource;
        // When status is completed/failed, we don't expect changes, so unsubscribe
        if (unsubscribeOnFinish && endSubscriptionStatusList.includes(currentStatus)) {
          get().internal.unsubscribeFromAiDataSource(id);
        }
        if (currentStatus === AiDataSourceStatus.COMPLETED) {
          if (onComplete && prevStatus !== currentStatus) {
            onComplete();
          }
          if (!get().aiSamples?.[aiDataSource.id]) {
            await get().fetchDataSourceSamples(
              aiDataSource.id,
              aiDataSource.samplesType === AiDataSourceSamplesType.TEXT ? ModelType.NLP : ModelType.ASR,
            );
          }
        }
        if (onFail && currentStatus === AiDataSourceStatus.FAILED && prevStatus !== currentStatus) {
          onFail();
        }
        set((state) => {
          if (state.aiDataSources[aiDataSource.id].step !== currentStep) {
            state.aiDataSources[aiDataSource.id] = aiDataSource;
          }
        });
      });
      set((state) => {
        state.subscriptions[id] = interval;
      });

      return () => get().internal.unsubscribeFromAiDataSource(id);
    },
    abortGeneration: async (id: AiDataSourceId) => {
      const response = await aiDataSourcesApi.abortGeneration({ executionId: id });
      if (!response) return false;
      set((state) => {
        state.aiDataSources[id].status = AiDataSourceStatus.ABORTED;
      });
      get().internal.unsubscribeFromAiDataSource(id);
      return true;
    },
    declineGeneration: async (id: AiDataSourceId) => {
      const response = await aiDataSourcesApi.declineGeneration({ executionId: id });
      if (!response) return false;
      set((state) => {
        state.aiDataSources[id].status = AiDataSourceStatus.DECLINED;
      });
      get().internal.unsubscribeFromAiDataSource(id);
      return true;
    },
    reset: () => {
      Object.values(get().subscriptions).forEach(clearInterval);
      set(initialState);
    },
    internal: {
      unsubscribeFromAiDataSource: (id: AiDataSourceId) => {
        clearInterval(get().subscriptions[id]);
        set((state) => {
          delete state.subscriptions[id];
        });
      },
    },
  })),
);

export const useAiDataSourcesStore = createStoreHook<AiDataSourcesState & AiDataSourcesActions>({
  store: aiDataSourcesStore,
  useShallow,
});
