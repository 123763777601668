import { Stack, Flex, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useSearchParams, Navigate, useParams } from 'react-router-dom';
import { IconWand } from '@tabler/icons-react';
import { PublishRouteParams, ROUTES } from 'routes/routes.config';
import { isAiModelType, AiModelType } from 'stores/aiPlatform';
import { useFlow } from 'stores/flows';
import { EmptyState } from 'components';

import { PageTitle } from '../components/PageTitle/PageTitle';

export const testIds = {
  wrapper: 'publish-page',
  publishButton: 'publish-page-new-publish-button',
};

export const PublishPage = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as PublishRouteParams;
  const flow = useFlow(flowId);
  const [params] = useSearchParams();
  const modelType = params.get('modelType') ?? '';
  const isValidModelType = isAiModelType(modelType);

  if (!isValidModelType) {
    return (
      <Navigate
        to={{
          pathname: ROUTES.AI_PLATFORM(customerId, flowId).PUBLISH,
          search: `?modelType=${AiModelType.NLP}`,
        }}
        replace
      />
    );
  }

  const pageTitle = t(`publishPage.pageTitle.${modelType}`);

  return (
    <Stack p='xl' gap='md' className='h-full' pos='relative' pb={50} data-testid={testIds.wrapper}>
      <Flex align='center' justify='space-between'>
        <PageTitle title={pageTitle} subtitle={flow.name} />
        <Button leftSection={<IconWand />} onClick={() => {}} data-testid={testIds.publishButton}>
          {t('publishPage.publishButton')}
        </Button>
      </Flex>

      <EmptyState
        title={t('publishPage.emptyState.title')}
        message={t('publishPage.emptyState.message')}
        py={80}
        flex={1}
        withBorder
      />
    </Stack>
  );
};
