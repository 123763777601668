import { DataGeneration, StepName } from '@ai-platform/common-types';
import {
  AiDataSource,
  AiDataSourceSamplesType,
  AiDataSourceStatus,
  AiDataSourceStep,
  ApiAiDataSource,
  DataSourceState,
} from './aiDataSources.types';

const stateToDataSourceStatus: Record<DataSourceState, AiDataSourceStatus> = {
  [DataSourceState.STARTED]: AiDataSourceStatus.IN_PROGRESS,
  [DataSourceState.DONE]: AiDataSourceStatus.COMPLETED,
  [DataSourceState.FAILED]: AiDataSourceStatus.FAILED,
  [DataSourceState.ABORTED]: AiDataSourceStatus.ABORTED,
  [DataSourceState.DECLINED]: AiDataSourceStatus.DECLINED,
};

const stepNameToDataSourceStep = (step?: StepName) => {
  switch (step) {
    case StepName.GET_FLOW_SETTINGS:
      return AiDataSourceStep.GET_FLOW_SETTINGS;
    case StepName.GENERATE_AND_STORE_TEXT_SAMPLES:
      return AiDataSourceStep.GENERATE_AND_STORE_TEXT_SAMPLES;
    case StepName.GENERATE_AND_STORE_SPEECH_SAMPLES:
      return AiDataSourceStep.GENERATE_AND_STORE_SPEECH_SAMPLES;
    case StepName.PROVISIONING_DATA_FILTERING_NOTEBOOK:
      return AiDataSourceStep.PROVISIONING_DATA_FILTERING_NOTEBOOK;
    case StepName.DATA_GENERATION_DONE:
      return AiDataSourceStep.DATA_GENERATION_DONE;
    default:
      return AiDataSourceStep.UNEXPECTED_ERROR;
  }
};

export function dataSourceFromApi(dataSource: ApiAiDataSource): AiDataSource | null {
  if (!dataSource) return null;
  const { settings } = dataSource;
  return {
    id: dataSource?.executionId,
    createdBy: {
      userId: dataSource?.triggeredBy,
      givenName: dataSource?.triggeredBy, // FIXME
      familyName: '', // FIXME
      email: '', // FIXME
      name: '', // FIXME
      roles: [], // FIXME
    },
    createdAt: dataSource.startTimestamp ?? undefined,
    endedAt: dataSource.endTimestamp ?? undefined,
    status: stateToDataSourceStatus[dataSource.state],
    step: stepNameToDataSourceStep(dataSource.currentStep?.stepName),
    samplesType:
      (dataSource.onlyTextSamples && AiDataSourceSamplesType.TEXT) ||
      (dataSource.onlyVoiceSamples && AiDataSourceSamplesType.VOICE) ||
      AiDataSourceSamplesType.BOTH,
    isSamplesGeneration: !dataSource.sampleExecutionId,
    settings: {
      // TODO make sure all DataGenSettings responses returns setting object, especially get-latest-data-source API
      initialSamplesNumber: dataSource.initialSamplesCount ?? undefined,
      language: settings?.language,
      numSamples: settings?.samplesCount,
      customerConfigSource: settings?.customerConfigLocation ?? undefined,
      customerConfigPromptsSource: settings?.customerConfigPromptsLocation ?? undefined,
      predefinedConfigSource: settings?.customerPredefinedTemplateLocation ?? undefined,
      externalTextFile: settings?.externalTextFileLocation ?? undefined,
      sampleExecutionId: dataSource.sampleExecutionId ?? undefined,
      onlyTextSamples: dataSource.onlyTextSamples ?? undefined,
      textGenSettings: settings?.textGenSettings ?? undefined,
      voiceGenSettings: {
        sliceRatio: settings?.voiceGenSettings?.sliceRatio ?? undefined,
        noiseRatio: settings?.voiceGenSettings?.noiseRatio ?? undefined,
      },
    },
  };
}

/**
 * Converts a DataGeneration object to an AiDataSource object.
 * @param dataGeneration - The DataGeneration object to convert.
 * @returns The corresponding AiDataSource object.
 */
export function dataSourceFromDataGeneration(dataGeneration: DataGeneration): AiDataSource {
  return dataSourceFromApi(dataGeneration as ApiAiDataSource)!;
}
