import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Paper, Button, Text, Box, Group, ThemeIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle, IconDownload, IconMail } from '@tabler/icons-react';
import {
  AiDataSource,
  AiDataSourceSamplesType,
  AiDataSourceStatus,
  fullGenSamplesNumberKey,
  useAiDataSourcesStore,
} from 'stores/aiPlatform';
import { useFlow } from 'stores/flows';
import { DataGenerationRouteParams } from 'routes/routes.config';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';

import classes from './DataGenerationActions.module.css';
import {
  TextDataGenerationSettingsModal,
  testIds as textSettingsModalTestIds,
} from '../../TextDataGenerationSettingsModal/TextDataGenerationSettingsModal';
import {
  VoiceDataGenerationSettingsModal,
  testIds as voiceSettingsModalTestIds,
} from '../../VoiceDataGenerationSettingsModal/VoiceDataGenerationSettingsModal';
import { AbortGenerationModal, testIds as abortModalTestIds } from './AbortGenerationModal/AbortGenerationModal';
import {
  DeclineGenerationModal,
  testIds as declineModalTestIds,
} from './DeclineGenerationModal/DeclineGenerationModal';

interface DataGenerationActionsProps {
  aiDataSource: AiDataSource;
}

export const testIds = {
  abort: 'data-generation-actions-abort',
  decline: 'data-generation-actions-decline',
  downloadSamples: 'data-generation-actions-download-samples',
  approveAndGenerate: 'data-generation-actions-approve-and-generate',
  fullGenerationNote: {
    icon: 'data-generation-actions-full-generation-note-icon',
    text: 'data-generation-actions-full-generation-note-text',
  },
  textSettingsModal: textSettingsModalTestIds,
  voiceSettingsModal: voiceSettingsModalTestIds,
  abortModal: abortModalTestIds,
  declineModal: declineModalTestIds,
};

export const DataGenerationActions = ({ aiDataSource }: DataGenerationActionsProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const flow = useFlow(flowId);
  const { createAiDataSource } = useAiDataSourcesStore(['createAiDataSource']);
  const [fullGenerationSamplesNumber] = useLocalStorage<number | null>({
    key: fullGenSamplesNumberKey,
    defaultValue: null,
    getInitialValueInEffect: false,
  });
  const [opened, { open, close }] = useDisclosure();
  const { isSamplesGeneration, samplesType } = aiDataSource;
  const [abortModalOpened, { open: openAbortModal, close: closeAbortModal }] = useDisclosure();
  const [declineModalOpened, { open: openDeclineModal, close: closeDeclineModal }] = useDisclosure();
  const isInProgress = aiDataSource.status === AiDataSourceStatus.IN_PROGRESS;
  const isFullGenerationInProgress = !isSamplesGeneration && isInProgress;
  const isSamplesCompleted = isSamplesGeneration && aiDataSource.status === AiDataSourceStatus.COMPLETED;
  const isFullGenerationCompleted = !isSamplesGeneration && aiDataSource.status === AiDataSourceStatus.COMPLETED;

  if (isFullGenerationCompleted) return null;

  const handleGenerateClick = () => {
    if (fullGenerationSamplesNumber) {
      onApproveAndGenerate();
    } else {
      open();
    }
  };

  const onApproveAndGenerate = async () => {
    setSubmitting(true);
    const {
      settings: {
        language,
        customerConfigPromptsSource,
        customerConfigSource,
        predefinedConfigSource,
        externalTextFile,
        onlyTextSamples,
        textGenSettings,
        voiceGenSettings,
      },
    } = aiDataSource;
    // TODO: Temporary fix for this tenant until properly fixed in BE
    const tenantId = customerId.replace(/^aiola$/, 'aiola-dev');

    const response = await createAiDataSource({
      flowId,
      flowVersion: flow.activeVersion!,
      tenantId,
      sampleExecutionId: aiDataSource.id,
      dataGenSettings: {
        customerConfigPromptsSource,
        customerConfigSource,
        predefinedConfigSource,
        externalTextFile,
        language,
        numSamples: fullGenerationSamplesNumber!,
        onlyTextSamples,
        textGenSettings,
        voiceGenSettings,
      },
    });
    if (!response) {
      notifications.show({
        color: 'red',
        title: t('dataGenerationPage.generateModal.errorMessage'),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    }
    setSubmitting(false);
  };
  return (
    <Paper className={classes.root}>
      {isInProgress && (
        <Button color='red' onClick={openAbortModal} data-testid={testIds.abort}>
          {t('dataGenerationPage.actions.abortButton')}
        </Button>
      )}
      {isFullGenerationInProgress && (
        <Group align='center' gap='tiny' mx='auto'>
          <ThemeIcon c='teal.7' variant='white'>
            <IconMail size={20} data-testid={testIds.fullGenerationNote.icon} />
          </ThemeIcon>
          <Text c='gray.6' data-testid={testIds.fullGenerationNote.text}>
            {t('dataGenerationPage.actions.fullGenerationNote')}
          </Text>
        </Group>
      )}
      {isSamplesCompleted && (
        <>
          <Button color='red' onClick={openDeclineModal} data-testid={testIds.decline}>
            {t('dataGenerationPage.actions.declineButton')}
          </Button>
          <Box flex={1} />
          <Button
            variant='transparent'
            color='grey.7'
            leftSection={<IconDownload />}
            data-testid={testIds.downloadSamples}
          >
            {t('dataGenerationPage.actions.downloadSamplesButton')}
          </Button>
          <Button loading={submitting} onClick={handleGenerateClick} data-testid={testIds.approveAndGenerate}>
            {t('dataGenerationPage.actions.approveAndGenerateButton')}
          </Button>
        </>
      )}

      {samplesType === AiDataSourceSamplesType.TEXT && (
        <TextDataGenerationSettingsModal
          opened={opened}
          enableSamplesInput
          settings={aiDataSource.settings}
          sampleExecutionId={aiDataSource.id}
          onClose={close}
        />
      )}
      {samplesType === AiDataSourceSamplesType.VOICE && (
        <VoiceDataGenerationSettingsModal
          opened={opened}
          settings={aiDataSource.settings}
          sampleExecutionId={aiDataSource.id}
          onClose={close}
        />
      )}
      <AbortGenerationModal
        opened={abortModalOpened}
        samplesType={aiDataSource.samplesType}
        generationId={aiDataSource.id}
        close={closeAbortModal}
      />
      <DeclineGenerationModal
        opened={declineModalOpened}
        samplesType={aiDataSource.samplesType}
        generationId={aiDataSource.id}
        close={closeDeclineModal}
      />
    </Paper>
  );
};
