import { Combobox, Input, InputBase, useCombobox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiDataSource, DataGenSettings, useAiDataSourcesStore } from 'stores/aiPlatform';
import { DataSourceSelectOption, testIds as optionTestIds } from './DataSourceSelectOption';

interface DataSourceSelectProps {
  onChange: (value: string | null) => void;
  onDataSourceOpen: (settings: DataGenSettings) => void;
  forceOpenOptions?: boolean;
}

export const testIds = {
  input: 'data-source-select-input',
  dropdown: 'data-source-select-dropdown',
  option: optionTestIds,
};

export const DataSourceSelect = ({ onChange, onDataSourceOpen, forceOpenOptions }: DataSourceSelectProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onOpenedChange: (shouldOpen) => (shouldOpen || forceOpenOptions ? open() : close()),
    opened,
  });
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<AiDataSource | null>(null);

  const dataSourceOptions = useMemo(() => Object.values(aiDataSources), [aiDataSources]);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(id) => {
        setSelectedOption(dataSourceOptions.find((item) => item.id === id) ?? null);
        onChange(id);

        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          label={t('trainingPage.trainigModal.dataSourceSelect.title')}
          description={t('trainingPage.trainigModal.dataSourceSelect.description')}
          component='button'
          type='button'
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents='none'
          multiline
          onClick={() => combobox.toggleDropdown()}
          data-testid={testIds.input}
        >
          {selectedOption ? (
            <DataSourceSelectOption onClick={onDataSourceOpen} {...selectedOption} placeholder />
          ) : (
            <Input.Placeholder>{t('trainingPage.trainigModal.dataSourceSelect.placeholder')}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options data-testid={testIds.dropdown}>
          {dataSourceOptions.map((item) => (
            <Combobox.Option value={item.id} key={item.id}>
              <DataSourceSelectOption {...item} onClick={onDataSourceOpen} checked={item.id === selectedOption?.id} />
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
