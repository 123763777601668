import { ActionIcon, Box, Flex, Space, Stack, Text } from '@mantine/core';
import { IconCheck, IconSettings } from '@tabler/icons-react';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import dayjs from 'dayjs';
import { AiDataSource, DataGenSettings } from 'stores/aiPlatform';

interface DataSourceSelectOptionProps extends AiDataSource {
  placeholder?: boolean;
  checked?: boolean;
  settings: DataGenSettings;
  onClick: (settings: DataGenSettings) => void;
}

export const testIds = {
  icon: 'data-source-select-option-icon',
  user: 'data-source-select-option-user',
  date: 'data-source-select-option-date',
  getOptionTestSettingsTip: (id: string) => `data-source-select-option-tip--${id}`,
  getOptionTestId: (id: string) => `data-source-select-option--${id}`,
};

export const DataSourceSelectOption = ({
  id,
  createdBy,
  createdAt,
  checked,
  placeholder,
  settings,
  onClick: open,
}: DataSourceSelectOptionProps) => (
  <Flex align='center' data-testid={testIds.getOptionTestId(id)}>
    {!placeholder && (
      <Box w={18} mx='tn'>
        {checked && <IconCheck size={18} data-testid={testIds.icon} />}
      </Box>
    )}
    <Stack gap={0}>
      <Text size='sm' data-testid={testIds.user}>{`${createdBy.givenName} ${createdBy.familyName}`}</Text>
      <Text size='xs' c='dark.2' data-testid={testIds.date}>
        {dayjs(createdAt).format(CLASSIC_DATE_TIME_FORMAT)}
      </Text>
    </Stack>
    <Space className='grow' />
    <ActionIcon
      onClick={(e) => {
        e.stopPropagation();
        open(settings);
      }}
      variant='transparent'
      data-testid={testIds.getOptionTestSettingsTip(id)}
    >
      <IconSettings size={24} />
    </ActionIcon>
  </Flex>
);
